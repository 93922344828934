import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import 'font-awesome/css/font-awesome.min.css';
import { Modal, Button, Dropdown } from 'react-bootstrap';

import '../css/header.css';
import logoIcon from "../images/logo-icon.png"
import logoText from "../images/logo-text-black.png"

import sun from "../images/sun.png"
import sun1 from "../images/sun1.png"
import moon from "../images/moon.png"
import moon1 from "../images/moon1.png"

import globe from "../images/globe.png"


import pdffile from "../images/whitepaper.pdf"

const app = "https://app.tatswap.com"
// const app = "http://43.204.99.101:8091"

class Header extends Component 
{
    darkTheme()
    {
        document.body.classList.remove('light_theme'); 
        document.body.classList.add('dark_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoWhite;
    }
    lightTheme()
    {
        document.body.classList.remove('dark_theme'); 
        document.body.classList.add('light_theme'); 
        // let image = document.getElementById('logo_inner');
        // image.src = logoInner;
    }
      
    showMobile()
    {
        //  document.getElementById("mobile_nav_item").classList.toggle('active');
        document.getElementById("mobile_nav_item").classList.toggle("left_stye");
        document.getElementById("mobileLayer").classList.toggle('active');
        document.getElementById("burger").classList.toggle('clicked');  
        document.getElementById("burger").classList.toggle("burger_stye")   
        
    }
   
    
      
    
   constructor(props)
   {
       super(props);
       this.state = {location:false}
   }
  
   componentDidMount()
   { 
    document.getElementById("header").classList.remove('header-scrolled');
    document.body.classList.add('dark_theme'); 
    // const location = this.props.location.pathname.split('/')[1];
    // console.log("location",window.location.pathname.split('/')[1]);
    // const currentLocation = window.location.pathname.split('/')[1];
    // if(currentLocation == 'Home')
    // {
        window.addEventListener('scroll', function() {
            if(window.pageYOffset >80)
            {
                document.getElementById("header").classList.add('header-scrolled');
            }
            else{
                document.getElementById("header").classList.remove('header-scrolled');
 
            }
        });
    // }
    // console.log(this.state);
           
   }
    render() {
    const {location} = this.props;


		return (
           <div>
               <div id="mobileLayer">
                   <div id="header" className="fixed-top-navbar header-scrolled py-2 py-lg-2">
               <div className="container container_custom" id="mobileshow">
               <header className={`${location}`}>

<div className="d-flex align-items-center">

    {/* <h1 className=""> */}
        {/* <a href="/"> */}
        {/* <img src={logo} /> */}
        <span className="logo_full mr-auto">
        {/* <img src={logoIcon} className="logo_icon" id="logo_inner"/>
        <img src={logoText} className="logo_text" id="logo_inner_text"/> */}

        </span>
        {/* </a> */}
        {/* </h1>        */}

    <nav className="nav-menu d-none d-lg-block">
          <ul>
          <li className="index-header-link"><a href="https://app.tatswap.com/">Home</a></li>
          <li className="index-header-link"><a href="https://exchange.tatswap.com/#/swap">Swap</a></li>
          <li className="index-header-link"><a href="https://exchange.tatswap.com/#/pool">Add Pool</a></li>


            <li className="index-header-link"><a href="https://app.tatswap.com/farms">Farms</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/pools">Stake</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/launchpad">Launchpad</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/lockedstaking">Locked Staking</a></li>
            <li className="index-header-link"><a href={app+'/tatearn'}>TatEarn</a></li>
            <li className="index-header-link"><a href={pdffile} target="_blank">White Paper</a></li>
            <li className="theme-icon">
            <div className="theme-btn">
                <span className="cursor_pointer icon_black fa fa-sun-o" onClick={this.darkTheme}></span>
         
            <span className="px-2 text-white slash_icon">/</span>
            <span className="cursor_pointer icon_black fa fa-moon-o" onClick={this.lightTheme}></span>
           </div> </li>
          

            <a href="https://app.tatswap.com/" className="get-started-btn" data-target="landcontact" id="reques">
                {/* <i className="fa fa-external-link mr-2" aria-hidden="true"></i> */}
                USE APP</a>
        
        </ul>
    </nav>
     <a href="https://app.tatswap.com/" class="get-started-btn mobile-btn d-block d-lg-none mr-3">USE APP</a>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={this.showMobile}><i className="fa fa-bars" aria-hidden="true" id="burger"></i></button>
   
    <nav className="mobile-nav d-lg-none" id="mobile_nav_item">
              <ul>
              <li className="mobile-link">
                   <a href="https://app.tatswap.com/" class="btn_gradient mr-3 mb-3">CONNECT WALLET
                   </a>
                   </li>
              <li className="index-header-link"><a href="https://app.tatswap.com/"><span class="fa fa-home mr-3"></span>Home</a></li>
              <li className="index-header-link"><a href="https://exchange.tatswap.com/#/swap"><span class="fas fa-chart-bar mr-3"></span>Swap</a></li>

              <li className="index-header-link"><a href="https://exchange.tatswap.com/#/pool"><span class="fas fa-plus-circle mr-3"></span>Add Pool</a></li>

            <li className="index-header-link"><a href="https://app.tatswap.com/farms"><span class="fas fa-tractor mr-3"></span>Farms</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/pools"><span class="fas fa-swimming-pool mr-3"></span>Stake</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/launchpad"><span class="fas fa-cubes mr-3"></span>Launchpad</a></li>
            <li className="index-header-link"><a href="https://app.tatswap.com/lockedstaking"><span class="fas fa-exchange-alt mr-3"></span>Locked Staking</a></li>
            <li className="index-header-link"><a href={app+'/tatearn'}><span class="fas fa-coins mr-3"></span>TatEarn</a></li>

               
            <li className="index-header-link"><a href={pdffile} target="_blank"><span class="fa fa-file mr-3"></span>White Paper</a></li>
            
            {/* <li className="index-header-link"><a href="#"><span class="fa fa-question-circle mr-3"></span>Support</a></li> */}
            
            
            <li>
            <ul className="mobile-social mb-3">
                <li><a href="https://twitter.com/tatswapdex?s=21" target="_blank" className=""><span className="fa fa-twitter"></span></a></li>
                <li><a href="https://telegram.me/tatearn" target="_blank" className=""> <span className=" fa fa-paper-plane"></span> </a></li>
                <li><a href={pdffile} target="_blank" className=""><span className="fa fa-file"></span></a></li>
                <li><a href="mailto:mailto:support@tatwap.com" target="_blank" className=""><span className="fa fa-envelope"></span></a></li>
            </ul>
            </li>
            <li className="theme-icon pl-3 mb-2 d-flex">
                <div className="theme-btn">
                    <span className="cursor_pointer icon_black fa fa-sun-o" onClick={this.darkTheme}></span>
                    
                        <span className="px-2 text-white slash_icon">/</span>
            <span className="cursor_pointer icon_black fa fa-moon-o" onClick={this.lightTheme}></span>
            </div>
           
                </li>
            {/* <li className="nav-item d-flex pl-3 pt-2">
                    <span className="text_purple  dr_span dr_span_landing">
                <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic-1" className="pl-2">
                <img src={globe} className="mr-2 icon_black" /> EN
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item>English</Dropdown.Item>
                <Dropdown.Item>Chinese</Dropdown.Item>
                <Dropdown.Item>Korean</Dropdown.Item>
                </Dropdown.Menu>
                </Dropdown>
                </span>
            </li> */}
         

            
            </ul>
        </nav>

</div>


</header>
</div>
</div>
</div>

           </div>
        );
    }
}



export default Header