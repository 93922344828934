import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';
import footerIcon1 from "../images/footer_icon_1.png"
import footerIcon2 from "../images/footer_icon_2.png"
import footerIcon3 from "../images/footer_icon_3.png"
import footerIcon4 from "../images/footer_icon_4.png"
import footerIcon5 from "../images/footer_icon_5.png"

import buttomimage from "../images/btn-submit.png"

import pdffile from "../images/whitepaper.pdf"
class Footer extends Component 
{ 
    
   
    render() {
		return (
           <div>
               <div className="footer">
               <div className="footer_bg_purple">
                   {/* <div className="footer_sec">
                   <div className="container conatiner_custom">
                       <div className="row mx-0">
                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><img src={footerIcon1} className="mr-2"/><span>Discord</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon2} className="mr-2"/><span>GitHub</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon3} className="mr-2"/><span>Twitter</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon4} className="mr-2"/><span>Telegram</span></Link></li>
                                   <li><Link to="/Home"><img src={footerIcon5} className="mr-2"/><span>Instagram</span></Link></li>
                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Aave Protocol</span></Link></li>
                                   <li><Link to="/Home"><span>Developers</span></Link></li>
                                   <li><Link to="/Home"><span>Security</span></Link></li>
                                   <li><Link to="/Home"><span>aTokens</span></Link></li>
                                   <li><Link to="/Home"><span>Bug Bounty</span></Link></li>
                                   <li><Link to="/Home"><span>Flash Loans</span></Link></li>                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Rate Switching</span></Link></li>
                                   <li><Link to="/Home"><span>Whitepaper</span></Link></li>
                                   <li><Link to="/Home"><span>Aavenomics</span></Link></li>
                                   <li><Link to="/Home"><span>Ecosystem</span></Link></li>
                                   <li><Link to="/Home"><span>Careers</span></Link></li>
                                   <li><Link to="/Home"><span>Branding</span></Link></li>                                    
                               </ul>

                           </div>

                           <div className="col-12 col-sm-6 col-lg-3">
                               <ul className="footer_ul">
                                   <li><Link to="/Home"><span>Rate Switching</span></Link></li>
                                   <li><Link to="/Home"><span>Whitepaper</span></Link></li>                                                                     
                               </ul>
                               <div className="subscribe_sec">
                                   <p>Subscribe</p>
                                   <input type="text" className="form-control" placeholder="Enter the Email"></input>
                                   <Link to="/Home"><img src={buttomimage} /></Link>
                               </div>

                           </div>
                   </div>

               </div>
               </div> */}
               <div className="footer_sec_2">
               <div className="container conatiner_custom">
                  
                   <ul className="footer_ul footer_ul_hor">
                                   
                                   <li><a href="https://twitter.com/tatswapdex?s=21" target="_blank" className="mr-3"><span className="fa fa-twitter"></span></a></li>
                                   <li><a href="https://telegram.me/tatearn" target="_blank" className="mr-3"> <span className=" fa fa-paper-plane"></span> </a></li>
                                   <li><a href={pdffile} target="_blank" className="mr-3"><span className="fa fa-file"></span></a></li>
                                   <li><a href="mailto:mailto:support@tatwap.com" target="_blank" className="mr-3"><span className="fa fa-envelope"></span></a></li>

                                    
                               </ul>
                               <p className="mb-0 text-center footer_text mb-0 mt-3">Copyright © 2021 . <span className="text_yelw">All Rights Reserved</span></p>
                   {/* <p className="mb-0 text-center footer_text mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p> */}
                   </div>
               </div>
               </div>
       
           </div>
           </div>
        );
    }
}

export default Footer